/* src/css/gallery.css */

.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .main-image-container {
    width: 75%;
    text-align: center;
    perspective: 1000px; /* Dodanie perspektywy */
  }
  
  .main-image {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-top-right-radius: 200px;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 200px;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5), 
                0 5px 15px rgba(0, 0, 0, 0.3); /* Dodanie cieni */

  }
  
  .main-image.full-screen {
    transform: scale(1.1);
  }
  
  .thumbnails-container {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
    border-radius: 5px;
  }
  
  .thumbnail:hover {
    transform: scale(1.05) rotateX(25deg) rotateY(-25deg); 
  }
  
  .full-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
  }
  
  .full-screen-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .thumbnail {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 1300px) {
   .main-image-container {
    display: none;
   } 
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 1001;
  }
  
  .left-arrow {
    left: 20px;
  }
  
  .right-arrow {
    right: 20px;
  }
  
  .arrow:hover {
    background: rgba(0, 0, 0, 0.8);
  }