.category-title {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: left;
}
.category-content {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.category-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;
}

.images-container {
  width: 70%;
  display: flex;
  flex-direction: row-reverse;
}

.category-item {
  background: #fff;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  color: #555;
  transition: all 0.3s ease;
  box-shadow: var(--btn-shadow);
}

.category-item:hover {
  cursor: pointer;
  background-color: var(--ocean);
  opacity: 80%; /* Zmiana tła podczas najechania */
  color: var(--navy); /* Zmiana koloru tekstu podczas najechania */
  scale: 1.03;
}

@media (max-width: 1500px) {
  .category-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .category-section {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 984px) {
  .category-container {
    width: 100%;
  }
}