.about-section {
  background-image: url('../assets/Zdjecie_tlo-min6.jpg');
  background-size: cover;
  background-position: 25% 80%;
  color: var(--navy);
  padding: 2rem;
}

.background-shadow {
  position: relative; 
}

.background-shadow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgb(255, 220, 200) 0%, rgba(255, 245, 235, 1) 10%);
  opacity: 45%;
  z-index: 1; 
  pointer-events: none; 
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.about-image {
  max-width: 680px;
  height: auto;
  display: block;
  box-shadow: var(--ocean-elements-shadow);
}

.text {
  max-width: 800px;
  z-index: 2;
}

h1.section-title {
  font-size: 2.5rem;
  font-family: var(--subheadingFont);
  color: var(--navy); 
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;
}

p {
  font-size: 1.125rem;
  line-height: 1.75;
  margin-bottom: 1.5rem;
}


.highlight {
  font-weight: unset;
}

/* For larger screens */
@media (min-width: 1573px) {
  .content {
    flex-direction: row;
    text-align: left;
  }

  .about-image {
    flex: 1;
    margin-right: 2rem;
  }

  .text {
    flex: 2;
  }
}

@media (min-width: 985px) {
  @keyframes slideInFromLeft {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideInFromRight {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }

    
  }
  
  /* Klasy animacji z opóźnieniami */
  .fade-in-slide-in {
    opacity: 0; /* Początkowa przezroczystość */
    animation: slideInFromLeft 1s ease-out forwards;
  }
  
  .fade-in-slide-in1 {
    opacity: 0;
    animation: slideInFromRight 1.5s ease-out forwards;
    animation-delay: 1s; /* Opóźnienie dla pierwszego paragrafu */
  }
  
  .fade-in-slide-in2 {
    opacity: 0;
    animation: slideInFromRight 1.5s ease-out forwards;
    animation-delay: 1.5s; /* Opóźnienie dla drugiego paragrafu */
  }
  
  .fade-in-slide-in3 {
    opacity: 0;
    animation: slideInFromRight 1.5s ease-out forwards;
    animation-delay: 2s; /* Opóźnienie dla trzeciego paragrafu */
  }
}


@media (max-width: 768px) {
  .about-image {
    max-width: 100%;
  }
}
