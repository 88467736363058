.offer-section {
  position: relative;
  color: var(--navy);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.offer-menu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #ddd;
  z-index: 1000;
}

.offer-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
  padding: unset;
  margin: 0;
  width: 100%;
  justify-content: center; 
}

.offer-list-item {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  font-size: 1rem;
  flex: 1;
  color: var(--navy);
  transition: all 0.3s ease;
  box-shadow: var(--btn-shadow);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.offer-list-item-link {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.offer-list-item:hover {
  background-color: var(--ocean-hover);
  opacity: 80%;
  color: var(--navy);
  scale: 1.05;
}

.offer-list-item.active {
  background-color: var(--ocean);
  color: var(--navy);
}

.offer-section.active .offer-list-item.active {
  z-index: 2;
}

h2,
h3 {
  font-family: var(--headingFont);
  color: var(--navy);
}

.slider {
  position: relative;
  max-width: 80%;
  height: 60vh;
  margin: 0 auto;
  overflow: hidden;
}

.slides {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
}

.slides img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 10;
  border-radius: 50%;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

h2 span {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}
h2 span::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 5px; 
  background-color: var(--ocean); 
  border-radius: 2px;
  transform: rotate(-2deg) skewX(-5deg); 
  z-index: -1; 
}

@media (min-width: 985px) {
  .offer-section {
    flex-direction: column;
    position: relative;
  }

  .offer-menu {
    justify-content: flex-start;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    box-shadow: -13px 24px 13px -13px rgba(66, 68, 90, 1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .offer-list {
    flex-direction: row; 
    gap: 1rem;
    list-style-type: none;
    padding: unset;
    margin: 0;
  }

  .category-transition {
    margin-top: 1rem;
    width: 100%; 
    padding: 2rem;
  }  
}