/* src/BackToTopButton.css */
.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #003366; /* Jaśniejszy odcień navy */
    color: #ffffff; /* Biały tekst */
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1000; /* Upewnia się, że przycisk jest nad innymi elementami */
  }
  
  .back-to-top.visible {
    opacity: 1;
  }
  
  .back-to-top:hover {
    background-color: #00274d; /* Zmiana koloru tła przy najechaniu */
    color: #ffd700; /* Zmiana koloru tekstu przy najechaniu */
  }
  