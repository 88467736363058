
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000; /* Wyższy z-index, aby modal był na wierzchu */
  }
  
  .modal-content {
    background: #fff;
    padding: 1rem;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }

  .modal-content-container {
    max-height: 350px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
  .modal-content-container img {
    width: 100%;
  }
  
  .modal-content button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--ocean);
    color: var(--navy);
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: var(--ocean-hover);
  }

  @media (max-width: 768px) {
    .modal-content {
      max-width: 450px;
    }
  }