/* Navbar Styles */
.navbar {

  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: var(--subheadingFont);
}

a {
  cursor: pointer;
}

.logo {
  background-image: url("../assets/logo-poziom.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 5rem;
  width: 15rem;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  box-sizing: border-box;
}

.nav-links li a {
  color: var(--navy);
  text-decoration: none;
  font-size: 1.75rem;
  transition: color 0.3s ease-in-out;
}
.nav-links li:last-child a{
  color: var(--navy);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.nav-links li a:hover {
  color: var(--ocean-hover);
}

.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: var(--navy);
  margin: 5px;
  transition: all 0.3s ease;
}

/* Mobile Navbar */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    display: none; 
    background-color: var(--navy);
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
  }

  .nav-links.open {
    display: flex; 
    margin: unset;
    background-color: #fff;
    padding-bottom: 1rem;
  }

  .burger {
    display: block; 
  }

  .burger.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .burger.toggle .line2 {
    opacity: 0;
  }

  .burger.toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .logo {
    width: 8rem;
  }
}
