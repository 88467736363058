* {
  box-sizing: border-box;
}

:root {
  --navy: #181c22;
  --ocean: rgb(85, 211, 211);
  --ocean-hover: rgb(109, 238, 238);
  --white: #fff;
  --subheadingFont: "Dancing Script", cursive;
  --bodyFont: "Lora", serif;

  --btn-shadow: 0 4px 6px rgba(102, 102, 102, 0.5);
  --ocean-elements-shadow: 0 4px 6px #00274d;
}

body {
  margin: 0;
  font-family: "Platypi", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1px;
  background-color: #ffffff;
  
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--subheadingFont);
}

h2 {
  font-size: 2.5rem;
}

#contact {
  background-image: url("../assets/pattern1.png");
  background-repeat: repeat; /* Zapobiega powtarzaniu obrazu */
  margin-top: 1rem;
  position: relative;
}

.container {
  width: 80%;
  margin: 0 auto;
}

