.contact-section {
  padding: 2rem;
  color: var(--white);
  text-align: center;
}

.contact-heading {
  font-size: 2rem;
  color: var(--white);
  margin-bottom: 2rem;
}

.contact-info p {
  font-size: 1.125rem;
  margin: 0.5rem 0;
}

.contact-info p strong {
  color: var(--white);
}

.social-icons {
  margin-top: 2rem;
}

.icon {
  margin: 0 1rem;
  font-size: 2rem;
  color: var(--white);
  transition: color var(--transition);
}

.icon.instagram:hover {
  color: #e1306c; /* Instagram color */
}

.icon.facebook:hover {
  color: #1877f2; /* Facebook color */
}

